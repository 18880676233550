<template>
  <div class="main">
    <div class="list-z">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="fs-18">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/project/index' }">工程项目</el-breadcrumb-item>
        <el-breadcrumb-item>项目详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main_box" v-if="!emptyInfo">
      <div class="main_info">
        <div class="flex_center main_info_title">
          <span>{{ actions.prjname }}</span>
        </div>
        <div class="main_info_table">
          <detail-page
            :cards="cards"
            :propsConfig="propsConfig"
          ></detail-page>
        </div>
      </div>
      <div class="main_tabs" v-loading="loadingStatus">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in elTabsList" :key="index">
            <div class="main_tabs_content">
              <div class="main_tabs_content_btn" v-if="item.name === 'basicEngineering'">
                <div class="flex_center main_tabs_content_btn_list">
                  <div :class="['flex_row_center', 'main_tabs_content_btn_list_item', tabsBtnIndex === index ? 'main_tabs_content_btn_list_item_on' : '']" v-for="(item, index) in tabsBtnList" :key="item.id" @click="onTabsBtn(index)">
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </div>
              <div class="main_tabs_content_tabs">
                <detail-page
                  :cards="cards1"
                  :propsConfig="propsConfig"
                ></detail-page>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="empty-detail" v-else>
        <span>未获取到项目详情数据</span>
    </div>
  </div>
</template>

<script>
  import cache from '@/libs/util.cache'
  import DetailPage from '@/components/detail-page/index.vue'
  import { urlBiddingInfo, urlContractInfo, urlReviewInfo, urlPermitInfo, urlCompletionInfo, urlProjectList } from '@/api/account'
  export default {
    name: "projectDetails",
    components: { DetailPage },
    data() {
      return {
        activeName: 'basicEngineering',
        loadingStatus: false,
        cards: [],
        cards1: [],
        actions: cache.getSessionStorage('projectDetails'),
        propsConfig: {
          labelStyle: 'width: 180px;',
          contentStyle: ''
        },
        elTabsList: [
          { label: '工程基本信息', name: 'basicEngineering', value: '' },
          { label: '招投标信息', name: 'biddingInformation', value: '' },
          { label: '合同登记信息', name: 'contractRegistration', value: '' },
          { label: '施工图审查', name: 'constructionDrawingReview', value: '' },
          { label: '施工许可', name: 'constructionPermit', value: '' },
          { label: '竣工验收', name: 'completionAcceptance', value: '' },
        ],
        tabsBtnList: [
          { name: '详细信息', id: 1 },
          { name: '参与单位及相关负责人', id: 2 },
          // { name: '单体信息', id: 3 }
        ],
        tabsBtnIndex: 0
      }
    },
    computed: {
      emptyInfo() {
        return !this.actions;
      }
    },
    mounted() {
      if (this.hasPrjnumFromRoute())
        this.getDetail((record) => {
          this.actions = record;
          if (!this.emptyInfo)
            this.getCards();
        });
      else
        this.getCards();
    },
    methods: {
      onTabsBtn(index) {
        if (this.tabsBtnIndex === index) return;
        this.tabsBtnIndex = index;
        this.getCards()
      },
      hasPrjnumFromRoute() {
        return !!this.$route.query.prjnum;
      },
      async getDetail(callback) {
        let record = null;
        const params = {
          prjnum: this.$route.query.prjnum,
          current: 1,
          size: 10
        }
        console.log("getDetail-->params=", params);
        try {
          // 获取数据的 API 请求
          const response = await urlProjectList(params);
          const records = response.data ? response.data.records : [];
          const total = response.data ? response.data.total : 0;
          record = (Array.isArray(records) && records.length > 0) ? records[0] : null;
        } catch (res) {
          console.error(error);
          this.$message.error("获取项目详情失败");
        } finally {
        }
        if (callback)
          callback.call(this, record);
        return record;
      },
      getCards() {
        if (this.tabsBtnIndex === 0) {
          const { prjnum, provinceprjnum, buildcorpname, corpcode, prjtypenum, prjpropertynum, allarea, allinvest, prjapprovallevelnum, projectplannum } = this.actions;
          this.cards = [{
            colsNum: 2,
            fields: [
              { label: '项目编号', value: prjnum || '-' },
              { label: '省级项目编号', value: provinceprjnum || '-' },
              { label: '建设单位', value: buildcorpname || '-' },
              { label: '建设单位统一社会信用代码', value: ((corpcode && corpcode[0]) ? corpcode[0] : '') || '-' },
              { label: '项目分类', value: prjtypenum || '-' },
              { label: '建设性质', value: prjpropertynum || '-' },
              { label: '总面积(平方米)', value: allarea || '-' },
              { label: '总投资(万元)', value: allinvest || '-' },
              { label: '立项级别', value: prjapprovallevelnum || '-' },
              { label: '立项文号', value: projectplannum || '-' }
            ]
          }];
          this.cards1 = this.cards;
        } else if (this.tabsBtnIndex === 1) {
          const { corprolenum, personname, corpcode } = this.actions;
          this.cards1 = [{
            colsNum: 2,
            fields: [
              { label: '企业角色', value: corprolenum || '-' },
              { label: '负责人', value: personname || '-' },
              { label: '统一社会信用代码', value: corpcode?.[0] || '-' }
            ]
          }]
        }
      },
      async handleClick(tab) {
        this.loadingStatus = true;
        const param = {
          current: 1,
          prinum: this.actions.prjnum,
          size: 10
        };
        let res;
        switch (tab.name) {
          case 'basicEngineering':
            this.cards1 = this.cards;
            break;
          case 'biddingInformation':
            res = await urlBiddingInfo(param);
            const { tenderclassnum, tendertypenum, tenderresultdate } = res.data.records;
            this.cards1 = [{
              colsNum: 2,
              fields: [
                { label: '招标类型', value: tenderclassnum || '-' },
                { label: '招标方式', value: tendertypenum || '-' },
                { label: '中标日期', value: tenderresultdate || '-' }
              ]
            }];
            break;
          case 'contractRegistration':
            res = await urlContractInfo(param);
            const { contracttypenum, contractmoney } = res.data.records;
            this.cards1 = [{
              colsNum: 2,
              fields: [
                { label: '合同类别', value: contracttypenum || '-' },
                { label: '合同金额(万元)', value: contractmoney || '-' }
              ]
            }];
            break;
          case 'constructionDrawingReview':
            res = await urlReviewInfo(param);
            const { onecensorispass, censoredate } = res.data.records;
            this.cards1 = [{
              colsNum: 2,
              fields: [
                { label: '审查状态', value: onecensorispass || '-' },
                { label: '审查完成时间', value: censoredate || '-' }
              ]
            }];
            break;
          case 'constructionPermit':
            res = await urlPermitInfo(param);
            const { area, builderlicencenum, releasedate } = res.data.records;
            this.cards1 = [{
              colsNum: 2,
              fields: [
                { label: '面积(平方米)', value: area || '-' },
                { label: '施工许可编号', value: builderlicencenum || '-' },
                { label: '发证日期', value: releasedate || '-' }
              ]
            }];
            break;
          case 'completionAcceptance':
            res = await urlCompletionInfo(param);
            const { factarea, factcost, edate, licenceno } = res.data.records;
            this.cards1 = [{
              colsNum: 2,
              fields: [
                { label: '实际面积(平方米)', value: factarea || '-' },
                { label: '实际造价(万元)', value: factcost || '-' },
                { label: '实际竣工日期', value: edate || '-' },
                { label: '竣工验收编号', value: licenceno || '-' },
              ]
            }];
            break;
        }
        this.loadingStatus = false;
      },
    }
  }
</script>

<style scoped lang="less">
  .main {
    padding-bottom: 40px;
    .list-z {
      text-align: left;
      font-weight: 500;
      color: #4e4e4e;
      line-height: 40px;
      margin-top: 35px;
      margin-bottom: 25px;
      .fs-18 {
        font-size: 18px;
      }
    }
    .main_box {
      .main_info {
        background-color: #fff;
        border: 1px solid #DCDCDC;
        .main_info_title {
          height: 74px;
          padding: 0 20px;
          border-bottom: 1px solid #DCDCDC;
          font-size: 24px;
          font-weight: bold;
          color: #1890FF;
        }
        .main_info_table {
          padding: 30px 20px;
        }
      }
      .main_tabs {
        padding-top: 20px;
        .main_tabs_content {
          .main_tabs_content_btn {
            padding-bottom: 20px;
            .main_tabs_content_btn_list {
              .main_tabs_content_btn_list_item {
                font-size: 18px;
                font-weight: bold;
                color: #4E4E4E;
                border-radius: 4px;
                padding: 0 12px;
                height: 40px;
                min-height: 40px;
                margin-right: 16px;
                cursor: pointer;
              }
              .main_tabs_content_btn_list_item_on {
                background-color: #EDF4FF;
                color: #1890FF;
              }
              .main_tabs_content_btn_list_item:last-child {
                margin-right: 0;
              }
            }
          }
          .main_tabs_content_tabs {
          }
        }
      }
    }    
    .empty-detail {
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-top: 16px;
        background: #edf4ff;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
    }
  }
</style>